.login-page-image-sec {
  background-color: #003D94;
  padding: 30px;
  height: 100%; 

  .title-container {
    p {
     font-size: 25px;
      color: white;
      margin: 0;
    }
  }
}

.img-container {
  display: flex;
  flex-direction: column;
  padding: 50px 5px 5px 35px;
  flex-grow: 1;
  //justify-content: space-around;

  h3:nth-child(1) {
    color: #fff;
    font-size: 50px;
    margin: 0;

  }

  h3:nth-child(2) {
    font-size: 45px;
    margin: 10px 0 0 0;
    color: white;
    line-height: 50px;
  }

  img {
    width: 95%;
    height: 50%;
    margin-top: 30px;
  }
}
