
.help-modal-container {
  z-index: 500;
  position: fixed;
  right: 5px;
  top: 115px;
  //bottom: 0;
  width: 50%;
  min-height: 20vh;
  max-height: 80vh;
  overflow: auto;
  background-color: white;
  padding: 10px 10px 10px 20px;
  border-radius: 15px !important;
}
